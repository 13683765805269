import React, { Fragment, useEffect, useMemo } from 'react'
import { navigate, PageProps } from 'gatsby'
import {
  Anchor,
  breakpoints,
  DefaultTheme,
  getStoredDistributionCenter,
  getStoredMarket,
  Market,
  useAuth,
  useLocation,
  useShoppingCart,
} from '@ecommerce/shared'
import styled, { css, ThemeProvider } from 'styled-components'
import loadable from '@loadable/component'
import { DynamicPage, PgPageProps } from '../../types/PgPages'
import { contentfulThemeToThemeExtension, LocationSelectorOption } from '../../graphql/global'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { backgroundImageStyles } from '../../utils/styles'
import { Icon } from '../../components/Icon/Icon'
import { BlockType } from '../../graphql/contentfulTypes'
import Hero from '../../components/Moments/Hero'
import { useCartStockErrorHandler } from '../../utils/errors'
import { extractBlocksSkus } from '../utils'
import useAlgoliaFetch from '../../hooks/useAlgoliaFetch'
import Steps from '../../components/Steps'
import LocationSelector from '../../components/LocationSelector'
import withPageTransition from '../../components/withPageTransition'
import useSession from '../../hooks/useSession'
import ProductVideo from '../../components/ProductDetail/ProductVideo'
import { BannerSliderWidget } from '../../components/BannerSlider'
import { BannerFluid, BannerFluidList } from '../../components/BannerFluid'
import slugFallback from '../../utils/slugFallback'
import { sendSelectItemEvent, sendPageViewEvent } from '../../utils/events'
import { generateProductUrl } from '../../config/siteBuild/utils'

const ProductSlidesList = loadable(() => import('../../components/Moments/ProductSlidesList'))
const PromosGrid = loadable(() => import('../../components/Moments/PromosGrid'))
const FilteredProductList = loadable(() => import('../../components/Moments/FilteredProductList'), { ssr: false })
const ProductsList = loadable(() => import('../home/SkuList'), { ssr: false })
const RecipeList = loadable(() => import('../../components/Recipes/RecipesList'))
const NewsList = loadable(() => import('../../components/News/NewsList'))
const VideosList = loadable(() => import('../../components/Moments/VideosList'))
const BrandsList = loadable(() => import('../../components/Moments/BrandsList'))
const HowItWorks = loadable(() => import('../../components/Moments/HowItWorks'))

type Props = PageProps<{}, { data: DynamicPage } & PgPageProps['pageContext']>

type AnchorWrapperProps = {
  hasAnchor: boolean
}

const TemplateWrapper = styled.div<{ backgroundImage?: string }>`
  color: ${({ theme }) => theme.colors.bodyText};
  padding-bottom: 40px;
  ${backgroundImageStyles()};

  .content-image {
    height: 400px;
  }

  .asset {
    max-height: 400px;
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .content-image {
      height: 370px;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .content-image {
      height: 400px;
    }
  }
`

const AnchorWrapper = styled.div<AnchorWrapperProps>`
  max-width: 1500px;
  margin: auto;
  display: none;
  padding: 20px 65px;

  .go-back {
    display: flex;
  }

  @media (${breakpoints.desktop.min}) {
    display: block;

    ${({ hasAnchor }) => !hasAnchor && `padding: 39px 65px;`}
  }
`

const Section = styled.div<{ paddingTop?: boolean }>`
  margin-top: 40px;

  h2 {
    text-align: center;
  }

  p {
    text-align: left;
  }

  ${({ paddingTop }) =>
    paddingTop &&
    css`
      @media (${breakpoints.desktop.min}) {
        padding-top: 56px;
      }
    `}
`

const RecipesPageTemplate = ({ pageContext }: Props) => {
  const storedCity = getStoredMarket()
  const { isBolivia } = useLocation()

  const {
    data: { title, slug, description, keywords = [], blocks, theme: pageTheme, navbar },
    markets,
    productCategories,
    currentDistributionCenter,
    currentMarket,
  } = pageContext
  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter
  const incomingCity = getStoredMarket() || currentMarket

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  const themeExtension = pageTheme ? contentfulThemeToThemeExtension(pageTheme) : {}
  const theme = (currentTheme: DefaultTheme) => ({
    ...currentTheme,
    colors: { ...currentTheme.colors, ...themeExtension },
  })
  const { resetAppState } = useSession()

  const stockErrorHandler = useCartStockErrorHandler()
  const themeColor = pageTheme?.brandDefault

  const skus = useMemo(() => extractBlocksSkus(blocks), [])
  const { products, loadingProducts } = useAlgoliaFetch({
    distributionCenter: storedDistributionCenter,
    skus,
    navigationState: {
      themeId: pageTheme?.contentful_id,
    },
  })

  const onSelect = async (option: LocationSelectorOption) => {
    const newCity = markets.find((c) => c.slug === option.slug) || markets[0] || ({} as Market)
    await resetAppState(`/${slug}`, {
      keepCart: true,
      keepAuth: true,
      newMarket: newCity,
    })
  }

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, event: 'SingleDynamicPage', title })
  }, [])

  useEffect(() => {
    if (incomingCity && storedCity && incomingCity.slug !== storedCity.slug) {
      resetAppState(`/${slug}`, { keepCart: true, keepAuth: true, newMarket: incomingCity })
    }
  }, [])

  if (!incomingCity?.slug && storedCity?.slug) {
    navigate(`/${slug}`)
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout
        title={title}
        navbarType={navbar?.type}
        navbarTitlte={navbar?.title}
        categories={productCategories}
        disableControls={!incomingCity?.slug}
      >
        <Seo keywords={keywords} description={description}>
          {themeColor && <meta name="theme-color" content={themeColor} />}
        </Seo>
        <TemplateWrapper backgroundImage={pageTheme?.bodyBackgroundImage?.file?.url}>
          {pageContext?.data?.navbar && (
            <AnchorWrapper hasAnchor={!!pageContext?.data?.navbar}>
              <Anchor onClick={() => navigate(`/`)} className="go-back">
                <Icon iconId="arrow_left" size="15" />
                <span>Volver al inicio</span>
              </Anchor>
            </AnchorWrapper>
          )}
          {blocks?.map((block) => {
            const { contentful_id } = block
            switch (block.blockType) {
              case BlockType.BANNER_FLUID: {
                return <BannerFluidList key={block.contentful_id} listId={contentful_id} />
              }

              case BlockType.PRODUCT_SLIDE: {
                return (
                  <ProductSlidesList
                    key={block.contentful_id}
                    listId={block.contentful_id}
                    themeId={pageTheme?.contentful_id}
                  />
                )
              }

              case BlockType.RECIPE: {
                return (
                  <RecipeList
                    {...block}
                    key={block.contentful_id}
                    listId={block.contentful_id}
                    actionSlug={block.actionSlug ? `${slugFallback(block.actionSlug)}` : undefined}
                  />
                )
              }

              default:
                return null
            }
          })}
          <br />
        </TemplateWrapper>
        {!incomingCity?.slug && (
          <LocationSelector onClose={() => null} hasCloseButton={false} excludeExternal onSelect={onSelect} />
        )}
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(RecipesPageTemplate)
